import PropTypes from 'prop-types';
import React from 'react';

import Avatar from 'core/components/Avatar.js';
import Dropdown from 'core/components/Dropdown.js';
import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';

import autobind from 'common/decorators/autobind.js';
import cookies from 'cookies.js';
import http from 'http.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

export default class AccountMenu extends React.Component {
    static propTypes = {
        allowSignup: PropTypes.bool,
        registerUrl: PropTypes.string,
    };

    static defaultProps = {
        allowSignup: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            segment: 'login',
            user: '',
        };
    }

    componentDidMount() {
        this.getUserInfo();
    }

    renderAvatar(user) {
        return (
            <>
                <Avatar
                    avatar={user.avatar}
                    displayName={user.displayName}
                    initials={user.initials}
                    profileId={user.profileId}
                />
                <span>{user.displayName}</span>
            </>
        );
    }

    get userMenuOptions() {
        const {user} = this.state;
        let previousLinkIsDivider = false;
        const options = [];
        user.links.forEach((link) => {
            const content = previousLinkIsDivider ? (
                <>
                    <span className="separator" />
                    <Link href={link.url}>{link.text}</Link>
                </>
            ) : (
                <Link href={link.url}>{link.text}</Link>
            );
            previousLinkIsDivider = link.divider;
            if (!link.divider) {
                options.push({
                    name: link.text,
                    content,
                });
            }
        });
        return options;
    }

    renderUserMenu() {
        const {user} = this.state;
        return (
            <Dropdown
                displayElement={this.renderAvatar(user)}
                options={this.userMenuOptions}
            />
        );
    }

    renderAccountMenu() {
        const {segment} = this.state;
        const {allowSignup} = this.props;
        return (
            <Dropdown customClass="account" displayText="Sign up/Log in">
                <div className="tabs">
                    <button
                        aria-selected={segment === 'login'}
                        id="login"
                        onClick={this.handleSegment}
                        role="tab"
                    >
                        {'Log in'}
                    </button>
                    {allowSignup && (
                        <button
                            aria-selected={segment === 'register'}
                            id="register"
                            onClick={this.handleSegment}
                            role="tab"
                        >
                            {'Sign up'}
                        </button>
                    )}
                </div>
                {segment === 'register'
                    ? this.renderRegister()
                    : this.renderLogIn()}
            </Dropdown>
        );
    }

    @autobind
    handleSegment(event) {
        event.preventDefault();
        this.setState({segment: event.target.id});
    }

    renderLogIn() {
        const csrftoken = cookies.get('csrftoken');
        const next = encodeURI(`${window.location.href}`);
        return (
            <>
                <form action="/accounts/login/" method="post">
                    <input
                        name="csrfmiddlewaretoken"
                        type="hidden"
                        value={csrftoken}
                    />
                    <label htmlFor="username">
                        {'Email address or username'}
                    </label>
                    <input
                        autoFocus
                        autoComplete="off"
                        id="username"
                        name="username"
                        required
                        type="text"
                    />
                    <label htmlFor="password">{'Password'}</label>
                    <input
                        autoComplete="off"
                        id="password"
                        name="password"
                        required
                        type="password"
                    />
                    <a href="/accounts/reset-password/">
                        {'Forgot your password?'}
                    </a>
                    <div className="options">
                        <label>
                            <input name="remember" type="checkbox" />
                            <Icon name="checkbox" />
                            {'Remember me'}
                        </label>
                    </div>
                    <input name="next" type="hidden" value={next} />
                    <button>{'Log in'}</button>
                </form>
            </>
        );
    }

    async getUserInfo() {
        const data = {};
        const url = '/api/base/v1/user-info/';
        const adminUrl = document.body.dataset.adminUrl;
        if (adminUrl) {
            // eslint-disable-next-line camelcase
            data.admin_url = adminUrl;
        }
        try {
            const response = await http.get({url, data});
            this.setState({user: snakeToCamelObjectKeys(response)});
        } catch (e) {
            this.setState({user: null});
        }
        this.setState({isLoaded: true});
    }

    renderRegister() {
        const {registerUrl} = this.props;
        const url = registerUrl ? registerUrl : '/professional/signup/';
        return (
            <div className="register">
                {!registerUrl && (
                    <a href="/accounts/signup/member/">
                        <Icon name="generalMembers" />
                        <Icon name="chevron" />
                        {'Sign up as a '}
                        <strong>{'General member'}</strong>
                    </a>
                )}
                <a href={url}>
                    <Icon name="generalPractitioners" />
                    <Icon name="chevron" />
                    {'Sign up as a '}
                    <strong>{'Health professional'}</strong>
                </a>
            </div>
        );
    }

    render() {
        const {isLoaded, user} = this.state;
        if (isLoaded && user) {
            return this.renderUserMenu();
        } else if (isLoaded) {
            return this.renderAccountMenu();
        } else {
            return null;
        }
    }
}
